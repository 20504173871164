import appStoreLogo from "../assets/app-store-badge.png";
import playStoreLogo from "../assets/play-store-badge.png";
import circleCheck from "../assets/circle-check.png";
import logo from "../assets/vertical-logo.png";
import whiteLogo from "../assets/white-vertical-logo.png";
import circleQuestion from "../assets/message-circle-question.png";

import icon1 from "../assets/icon-1.png";
import icon2 from "../assets/icon-2.png";
import icon3 from "../assets/icon-3.png";
import line from "../assets/line.svg";
import discount from "../assets/discount.svg";

import mobileImage1 from "../assets/mobile-image-1.png";
import mobileImage2 from "../assets/mobile-image-2.png";
import { track } from "@amplitude/analytics-browser";
import queryString from "query-string";
import React, { useEffect, useState } from "react";

function Desktop() {
  const [curr, setCurr] = useState(0);
  const [transition, setTransition] = useState('transform 3000ms ease-in-out');
  const [products1, setProducts1] = useState([]);
  const [products2, setProducts2] = useState([]);
  useEffect(() => {
    setTimeout(() => {
      if(curr > 7) {
        setCurr(0);
        setTransition('transform 0ms ease-in-out')
      } else {
        setCurr(curr+1);
        setTransition('transform 3000ms ease-in-out')
      }
    }, 3500);
  }, [curr]);

  useEffect(() => {
    track("enter_landing");
    fetch("https://category.greenhada.store/products", {
      method: "GET",
      headers: {
        'Content-type': 'application/json'
      },
    }).then(res => res.json()).then(res => {
      const res2 = res.filter(i => {
        return i.title.includes("샤인") || i.title.includes("사과") || i.title.includes("토마토") || i.title.includes("고구마") || i.title.includes("대봉") ? i : null; 
      })
      setProducts1(res.slice(0, 8).concat(res.slice(0, 8)));
      setProducts2(res2.slice(0, 8).concat(res2.slice(0, 8)));
    });
  }, []);

  const downloadIOS = () => {
    track("landing_click_appstore");
    window.location =
      "https://apps.apple.com/kr/app/%EA%B7%B8%EB%A6%B0%ED%95%98%EB%8B%A4-%EC%B4%88%EC%A0%80%EA%B0%80-%EB%86%8D%EC%82%B0%EB%AC%BC-%EB%8F%84%EB%A7%A4-%EC%96%B4%ED%94%8C/id6468979011" +
      utmTag;
  };

  const downloadAOS = () => {
    track("landing_click_google");
    window.location =
      "https://play.google.com/store/apps/details?id=com.postalpine.greenhada&" +
      utmTag.slice(1, utmTag.length);
  };

  const downloadApp = () => {
    track("landing_click_install");
    if (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i)
    ) {
      downloadIOS();
    } else {
      downloadAOS();
    }
  };

  let qs = queryString.parse(window.location.search);
  if (qs.utm_source === undefined) {
    qs.utm_source = "landing";
  }
  qs.utm_content = "landing";
  let utmTag =
    Object.keys(qs).length === 0 ? "" : "?" + queryString.stringify(qs);

  return (
    <div className="overflow-x-hidden">
      <nav className="px-[40px] text-[16px] justify-center">
        <img
          src={logo}
          alt=""
          className="h-[50px] float-left ml-[20px] mt-[4px]"
        />
        <a
          href={"https://seller.greenhada.store" + utmTag}
          onClick={() => {
            track("landing_click_form_1");
          }}
        >
          <div className="px-[12px] float-right leading-[40px] h-[40px] mt-[8px] hover:bg-gehagray-10 font-[600] rounded-[6px]">
            입점문의
          </div>
        </a>
      </nav>

      <div className="px-[40px]">
        <div className="h-[60px]"></div>

        <div className="bg-gehagreen-70 text-center justify-center items-center pt-[114px] rounded-[32px] bg-[url('./assets/hero-banner.png')] bg-cover bg-opacity-[0.65]">
          <p
            className="w-fit m-auto text-gehagray-90 text-[18px]
          font-pyeongchang font-[400] leading-[24px] px-[16px] py-[8px] bg-gehagreen-10 rounded-[100px]"
          >
            온라인 최저가 보장
          </p>
          <p className="mt-[16px] h1 leading-[120%] font-geekble text-gehawhite">
            초저가 신선 식자재 도매
          </p>
          <img src={whiteLogo} alt="" className="m-auto mt-[8px] w-[216px]" />
          <div className="flex m-auto justify-center mt-[60px]">
            <button onClick={downloadAOS}>
              <img src={playStoreLogo} alt="" className="w-[134px]" />
            </button>

            <button onClick={downloadIOS}>
              <img src={appStoreLogo} alt="" className="w-[130px] ml-[8px]" />
            </button>
          </div>
          <div className="h-[92px]"></div>
        </div>

        <div className="justify-center text-center font-bold text-[32px]">
          <p className="mt-[100px]">초특가 할인 판매 상품</p>
          <div className="h-[60px]">

          </div>
          <div className="flex" style={{
            transform: `translateX(-${406 * (curr)}px)`,
            transition: transition
          }}>
            {
              products1.map((p, i) => {
                return (
                  <div className="flex mr-[20px] border-[1px] border-gehagreen-50 rounded-[6px]" key={`wrap_${i}_${p.id}`}>
                    <div className="h-[148px] w-[148px] rounded-l-[6px]" style={{
                      backgroundImage: `url("${p.thumbnail}")`,
                      backgroundSize: 'cover'
                      }} />

                    <div className="bg-gehagreen-10 w-[236px] h-[148px] text-left pl-[20px] rounded-r-[6px]" key={`right_${i}_${p.id}`}>
                      <p className="text-gehagray-90 text-[18px] leading-[24px] tracking-[-0.5px] pt-[13px] max-w-[196px]">
                        {p.title}
                      </p>
                      <div className="w-[100%] bottom-[57px] text-[12px] font-[500] flex fixed">
                        <div className="py-[2px] px-[6px] font-[500] bg-gehagray-50 text-white rounded-l-[4px] border-[1px] border-gehagray-50">
                          정상가
                        </div>
                        <div className="py-[2px] px-[6px] font-[500] text-gehagray-50 border-[1px] border-gehagray-50 rounded-r-[4px]">
                          {`${p.originalPrice.toLocaleString()}원`}
                        </div>
                      </div>
                      <div className="w-[100%] bottom-[13px] leading-[120%] tracking-[-0.5px] text-gehaerror-50 fixed">
                        {`${p.price.toLocaleString()}원`}
                      </div>
                      <img alt="product1-line" src={line} className="bottom-[52.5px] pl-[49px] fixed"/>
                      <img alt="product1-discount" src={discount} className="bottom-[13px] pl-[147px] fixed"/>

                    </div>

                  </div>
                );
              })
            }
          </div>

          <div className="h-[24px]">

          </div>

          <div className="flex" style={{
            transform: `translateX(${406 * (curr-9)}px)`,
            transition: transition
          }}>
            {
              products2.map((p, i) => {
                return (
                  <div className="flex mr-[20px] border-[1px] border-gehagreen-50 rounded-[6px]" key={`wrap_${i}_${p.id}`}>
                    <div className="h-[148px] w-[148px] rounded-l-[6px]" style={{
                      backgroundImage: `url("${p.thumbnail}")`,
                      backgroundSize: 'cover'
                      }} />

                    <div className="bg-gehagreen-10 w-[236px] h-[148px] text-left pl-[20px] rounded-r-[6px]" key={`right_${i}_${p.id}`}>
                      <p className="text-gehagray-90 text-[18px] leading-[24px] tracking-[-0.5px] pt-[13px] max-w-[196px]">
                        {p.title}
                      </p>
                      <div className="w-[100%] bottom-[57px] text-[12px] font-[500] flex fixed">
                        <div className="py-[2px] px-[6px] font-[500] bg-gehagray-50 text-white rounded-l-[4px] border-[1px] border-gehagray-50">
                          정상가
                        </div>
                        <div className="py-[2px] px-[6px] font-[500] text-gehagray-50 border-[1px] border-gehagray-50 rounded-r-[4px]">
                          {`${p.originalPrice.toLocaleString()}원`}
                        </div>
                      </div>
                      <div className="w-[100%] bottom-[13px] leading-[120%] tracking-[-0.5px] text-gehaerror-50 fixed">
                        {`${p.price.toLocaleString()}원`}
                      </div>
                      <img alt="product2-line" src={line} className="bottom-[52.5px] pl-[49px] fixed"/>
                      <img alt="product2-discount" src={discount} className="bottom-[13px] pl-[147px] fixed"/>
                    </div>

                  </div>
                );
              })
            }
          </div>
          
        </div>

        <div className="justify-center text-center font-bold text-[32px]">
          <p className="mt-[200px]">초저가 신선 식자재, 어떻게 가능한가요?</p>
          <div className="mt-[60px] mb-[200px]">
            <div className="flex h-[48px]">
              <div className="w-1/3 flex">
                <div className="w-full mr-[84px]"></div>
                <div className="w-full ml-[84px] border-dashed border-gehagreen-30 border-b-2"></div>
              </div>
              <div className="w-1/3 flex ml-[-4px]">
                <div className="w-full mr-[84px] border-dashed border-gehagreen-30 border-b-2"></div>
                <div className="w-full ml-[84px] border-dashed border-gehagreen-30 border-b-2"></div>
              </div>
              <div className="w-1/3 ml-[-4px] flex">
                <div className="w-full mr-[84px] border-dashed border-gehagreen-30 border-b-2"></div>
                <div className="w-full ml-[84px]"></div>
              </div>
            </div>
            <div className="mt-[-48px] flex">
              <div className="w-1/3">
                <img src={icon1} alt="" className="mt-[11px] w-[80px] m-auto" />
                <p className="mt-[24px] text-gehagray-90 text-[22px] font-[700] leading-[120%]">
                  생산자 직거래
                </p>
                <p className="mt-[16px] text-gehagray-70 text-[16px] font-normal leading-[24px] tracking-[-0.32px]">
                  불필요한 중간 유통 마진, 비효율적인 물류 비용을
                  <br />
                  줄이고 생산자 직거래로 가격을 낮췄습니다.
                </p>
              </div>
              <div className="w-1/3 mx-[24px]">
                <img src={icon2} alt="" className="mt-[11px] w-[80px] m-auto" />
                <p className="mt-[24px] text-gehagray-90 text-[22px] font-[700] leading-[120%]">
                  박스 단위 판매
                </p>
                <p className="mt-[16px] text-gehagray-70 text-[16px] font-normal leading-[24px] tracking-[-0.32px]">
                  소분 및 소포장에 들어가는 인건비를 줄이고
                  <br />
                  판매 단위를 일원화해 가격을 더 낮췄습니다.
                </p>
              </div>
              <div className="w-1/3 ">
                <img src={icon3} alt="" className="mt-[11px] w-[80px] m-auto" />
                <p className="mt-[24px] text-gehagray-90 text-[22px] font-[700] leading-[120%]">
                  낮은 수수료
                </p>
                <p className="mt-[16px] text-gehagray-70 text-[16px] font-normal leading-[24px] tracking-[-0.32px]">
                  플랫폼 수수료를 시중 유통사 보다 50% 낮게 책정,
                  <br />
                  생산자들이 낮은 가격에 판매할 수 있도록 지원합니다.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex">
          <div className="w-1/2 justify-center text-right pr-[147px] pt-[125px]">
            <p className="h2 text-gehagray-100">
              타사 대비 &nbsp;
              <b className="text-gehagreen-50 text-[40px] tracking-[-2.8px] font-[700]">
                30
              </b>
              <b className="text-gehagreen-50 text-[30px] tracking-[-2.1px] font-[700]">
                %
              </b>
              &nbsp;저렴한
              <br />
              가격과 무료배송
            </p>
            <p className="h3 mt-[24px]">
              농산물은 도매시장에서 구매하는 게 더 싸다? <br />
              <b className="font-[600] text-gehagreen-50">
                그린하다에서는 더 저렴
              </b>
              합니다.
            </p>
          </div>
          <div className="w-1/2 justify-center h-[440px] bg-gehagray-10 rounded-[32px]">
            <img
              alt=""
              className="m-auto mt-[33px] w-[309px]"
              src={mobileImage1}
            />
          </div>
        </div>

        <div className="h-[200px]"></div>

        <div className="flex">
          <div className="w-1/2 justify-center h-[440px] bg-gehagray-10 rounded-[32px]">
            <img
              alt=""
              className="m-auto mt-[37px] w-[309px]"
              src={mobileImage2}
            />
          </div>
          <div className="w-1/2 justify-center pl-[126px] pt-[47px]">
            <p className="h2">
              초저가지만 믿을 수 있는 품질
              <br />
              상품 불만족시
              <b className="text-gehagreen-50 text-[40px] tracking-[-2.8px] font-[700]">
                100
              </b>
              <b className="text-gehagreen-50 text-[30px] tracking-[-2.1px] font-[700]">
                %
              </b>
              환불 보장
            </p>

            <div className="mt-[24px] flex text-[20px] text-gehagray-80 tracking-[-0.2px] leading-[30px]">
              <img alt="" src={circleCheck} className="h-[24px] mt-[3px]" />
              <p className="pl-[12px]">믿고 구매할 수 있는 실구매 리뷰</p>
            </div>
            <div className="mt-[10px] flex text-[20px] text-gehagray-80 tracking-[-0.2px] leading-[30px]">
              <img alt="" src={circleCheck} className="h-[24px] mt-[3px]" />
              <p className="pl-[12px]">품질 이상 시 100% 환불 보장 제도</p>
            </div>
            <div className="mt-[10px] flex text-[20px] text-gehagray-80 tracking-[-0.2px] leading-[30px]">
              <img alt="" src={circleCheck} className="h-[24px] mt-[3px]" />
              <p className="pl-[12px]">
                도매 시장의 경매, 반출 대기 시간을 최소화한
                <br />
                신선한 농산물
              </p>
            </div>
            <br />

            <button onClick={downloadApp}>
              <div className="mt-[32px] bg-gehagreen-50 w-fit text-gehawhite font-[600] text-[16px] px-[16px] leading-[40px] rounded-[8px]">
                그린하다 앱 설치하기
              </div>
            </button>
          </div>
        </div>

        <div className="h-[200px]"></div>

        <div className="flex">
          <div className="w-1/2 justify-center text-right pr-[147px] pt-[64px]">
            <p className="h2 text-gehagray-100">
              가격 혁신의 핵심은
              <br />
              생산자와의 상생입니다.
            </p>
            <p className="h3 mt-[24px]">
              그린하다는&nbsp;
              <b className="font-[600] text-gehagreen-50">유통마진 제거</b>
              와&nbsp;
              <b className="font-[600] text-gehagreen-50">낮은 수수료</b>
              를 통해
              <br />
              생산자가&nbsp;
              <b className="font-[600] text-gehagreen-50">대량으로 판매</b>
              할 수 있도록
              <br />
              적극 투자합니다.
            </p>
            <div className="w-full justify-right">
              <a
                href={"https://seller.greenhada.store" + utmTag}
                onClick={() => {
                  track("landing_click_form_2");
                }}
              >
                <div className="inline-block mt-[32px] bg-gehagreen-50 w-fit text-gehawhite font-[600] text-[16px] px-[16px] leading-[40px] rounded-[8px]">
                  그린하다 입점하기
                </div>
              </a>

              <div className="mt-[16px] text-gehaerror-50 text-[16px] font-[600] leading-[24px] tracking-[-0.16px]">
                지금 입점 시 판매 수수료 50% 할인(선착순)
              </div>
              <div className="mt-[-12px] mr-[-4px] bg-gehaerror-10 h-[11px] w-[280px] float-right"></div>
            </div>
          </div>

          <div className="w-1/2 justify-center h-[440px] bg-gehagray-10 rounded-[32px] bg-[url('./assets/image-3.png')] bg-cover"></div>
        </div>

        <div className="h-[200px]"></div>

        <div className="bg-gehagreen-10 text-center justify-center items-center pt-[62px] rounded-[32px]">
          <p className="h1 leading-[120%] font-geekble text-gehagreen-50 text-[36px]">
            그린하다는 농산물 유통 과정을 혁신하고 <br /> 생산자와 상생하는 도매
            직거래 플랫폼 입니다.
          </p>
          <div className="flex m-auto justify-center mt-[60px]">
            <button onClick={downloadAOS}>
              <img alt="" src={playStoreLogo} className="w-[134px]" />
            </button>

            <button onClick={downloadIOS}>
              <img alt="" src={appStoreLogo} className="w-[130px] ml-[8px]" />
            </button>
          </div>
          <div className="h-[62px]"></div>
        </div>

        <div className="h-[200px]"></div>
      </div>

      <div className="bg-gehagray-10 text-gehagray-70 pt-[32px] px-[110px] pb-[90px]">
        <div className="flex">
          <a href="https://postalpine.notion.site/d78b43dfa85941bd88d626216bfb6f6d?pvs=4">
            <div className="font-bold text-[15px] tracking-[-0.5px] leading-[22px]">
              서비스 이용 약관
            </div>
          </a>

          <a href="https://postalpine.notion.site/94b462741c38455885202a98ce9f824e?pvs=4">
            <div className="ml-[24px] font-bold text-[15px] tracking-[-0.5px] leading-[22px]">
              개인정보처리방침
            </div>
          </a>
        </div>

        <div className="mt-[24px] h-[1px] bg-gehagray-20"></div>

        <div className="pb-[90px]">
          <div className="float-left mt-[24px] whitespace-pre-wrap text-[12px] tracking-[-0.5px] leading-[18px]">
            {/* (주) 포스트알파인  |  사업자등록번호 : 518-86-01993  |  대표이사 : 김지현<br/> */}
            (주) 포스트알파인 | 사업자등록번호 : 518-86-01993 | 대표자 : 김지현
            | 통신판매업 신고번호 : 2023-서울마포-3205 <br />
            주소 : 서울특별시 마포구 마포대로 122, 11층(신용보증기금빌딩) <br />
            전화번호 : 02-2088-4661 <br />
            <br />© Postalpine Inc. All rights reserved.
          </div>

          <div className="float-right mt-[24px] text-[12px]">
            고객센터 운영시간 : 10:00 ~ 19:00 (주말, 공휴일 제외)
            <a href="https://n13z1.channel.io">
              <div className="flex w-full justify-center text-center mt-[8px] bg-gehagray-10 bg-gehawhite border-[1px] border-solid text-gehagray-90 border-gehagray-20 text-[14px] leading-[33px] rounded-[8px]">
                <img
                  alt=""
                  src={circleQuestion}
                  className="mt-[4px] mr-[4px] w-[24px] h-[24px]"
                />
                고객센터
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Desktop;
