import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { init } from "@amplitude/analytics-browser";
import queryString from "query-string";

import Desktop from './pages/Desktop';
import Mobile from './pages/Mobile';

const MAX_MOBILE_SIZE = 767;
const MIN_TABLET_SIZE = 768;
const MAX_TABLET_SIZE = 1279;
const MIN_DESKTOP_SIZE = 1280;

function Main() {
  const isDesktop = useMediaQuery({
    query: `(min-width: ${MIN_DESKTOP_SIZE}px)`,
  });
  const isTablet = useMediaQuery({
    query: `(min-width: ${MIN_TABLET_SIZE}px) and (max-width: ${MAX_TABLET_SIZE}px)`,
  });
  const isMobile = useMediaQuery({
    query: `(max-width: ${MAX_MOBILE_SIZE}px)`,
  });

  useEffect(() => {
    init("be9b8b281c4f177f748eb113dfa6cc68");
  }, [])
  return (
    <div>
      {isDesktop && <Desktop/>}
      {isTablet && <Desktop/>}
      {isMobile && <Mobile/>}
    </div>
    
  );
}

function AppRedirection() {

  useEffect(() => {
    window.location.href="greenhada://"

    let qs = queryString.parse(window.location.search);
    if (qs.utm_source === undefined) {
      qs.utm_source = "landing";
    }
    qs.utm_content = "landing";
    let utmTag =
      Object.keys(qs).length === 0 ? "" : "?" + queryString.stringify(qs);

    const downloadIOS = () => {
      window.location =
        "https://apps.apple.com/kr/app/%EA%B7%B8%EB%A6%B0%ED%95%98%EB%8B%A4-%EC%B4%88%EC%A0%80%EA%B0%80-%EB%86%8D%EC%82%B0%EB%AC%BC-%EB%8F%84%EB%A7%A4-%EC%96%B4%ED%94%8C/id6468979011" +
        utmTag;
    };
  
    const downloadAOS = () => {
      window.location =
        "https://play.google.com/store/apps/details?id=com.postalpine.greenhada&" +
        utmTag.slice(1, utmTag.length);
    };
  
    const downloadApp = () => {
      if (
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i)
      ) {
        downloadIOS();
      } else {
        downloadAOS();
      }
    };

    setTimeout(() => {
      downloadApp();
    }, 1000);
  }, [])
  return (
    <div className="mt-[50px] justify-center text-center font-bold text-[24px]">
      <a href="greenhada://">그린하다 앱으로 이동하기</a>
    </div>
  )
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/app" element={<AppRedirection />} />
      </Routes>
    </Router>
  );
}

export default App;
